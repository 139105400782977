<template>
  <AppPage hide-bottom-bar container appBarColor="transparent">
    <template v-slot:app-bar>
      <v-btn icon @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <p class="subtitle-2 mb-0">
        {{ $vuetify.lang.t("$vuetify.step_1_of_2") }}
      </p>
    </template>
    <div class="auth-container">
      <div>
        <h2>{{ $vuetify.lang.t("$vuetify.create_account") }}</h2>
        <p class="subtitle-1">
          {{
            $vuetify.lang.t("$vuetify.enter_your_details_below_and_get_started")
          }}
        </p>

        <v-form ref="form" v-model="isValid">
          <v-text-field
            v-model="name"
            outlined
            hide-details
            class="rounded-lg mb-3"
            :rules="[rules.required]"
            :label="$vuetify.lang.t('$vuetify.name')"
          ></v-text-field>

          <v-text-field
            v-model="email"
            outlined
            hide-details
            type="email"
            class="rounded-lg mb-3"
            :rules="[rules.required, rules.validEmail]"
            :label="$vuetify.lang.t('$vuetify.email')"
          ></v-text-field>

          <!-- <v-text-field
            v-model="contact"
            type="number"
            outlined
            label="Phone Number"
            :rules="[rules.required]"
            class="rounded-lg mb-3"
            hide-details
          ></v-text-field> -->

          <div class="mb-3 enter-address" v-ripple @click="addAddress = true">
            <p>
              {{
                address ||
                $vuetify.lang.t("$vuetify.enter_your_address") | trim(28, "...")
              }}
            </p>
            <v-icon>mdi-arrow-right</v-icon>
          </div>

          <VuePhoneNumberInput
            v-model="contact"
            error-color="#FF5F5F"
            valid-color="#0DBFF2"
            color="#0DBFF2"
            size="lg"
            required
            :border-radius="8"
            no-example
            @update="validatePhone"
            :default-country-code="country_code"
            class="number-input"
          />

          <h3 class="mb-2 mt-5">{{ $vuetify.lang.t("$vuetify.gender") }}</h3>
          <ToggleBtn v-model="gender" :lists="genderList" />
        </v-form>
      </div>

      <div>
        <app-button class="mt-3" @click="nextStep" :disabled="!isValid">{{
          $vuetify.lang.t("$vuetify.next")
        }}</app-button>

        <div class="text-center">
          <p class="text-center grey--text text--darken-2 mt-4 mb-0">
            {{ $vuetify.lang.t("$vuetify.already_have_a_account") }}
            <router-link to="/login" class="text-decoration-none">{{
              $vuetify.lang.t("$vuetify.login")
            }}</router-link>
          </p>
        </div>
      </div>
    </div>

    <img contain src="@/assets/images/paws-top.png" class="paws-top" />

    <!-- bottom-sheet -->
    <v-bottom-sheet v-model="addAddress" fullscreen>
      <v-sheet class="pa-4 address-comp" height="100%">
        <div class="map-input">
          <h1 class="heading my-4">Address</h1>
          <gmap-autocomplete
            placeholder="Enter your address"
            @place_changed="setPlace"
          >
          </gmap-autocomplete>
        </div>

        <div>
          <app-button class="mb-10" @click="addAddress = false" color="error"
            >Close</app-button
          >
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </AppPage>
</template>

<script>
import ToggleBtn from "@/components/form/toggle-btn";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import { createNamespacedHelpers } from "vuex";
const userModule = createNamespacedHelpers("user");
import { validEmail, required } from "@/helpers/form-rules";
import { GENDER_LIST } from "@/constants";

export default {
  name: "register",
  components: {
    VuePhoneNumberInput,
    ToggleBtn,
  },
  data() {
    return {
      validPhone: false,
      addAddress: false,
      isValid: false,
      formatted_number: "",
    };
  },
  computed: {
    ...userModule.mapState(["address"]),
    ...userModule.mapGetters(["GET_USER_KEY", "IS_VALID_INPUT"]),
    name: {
      set(value) {
        this.SET_USER_FIELD({ key: "name", value });
      },
      get() {
        return this.GET_USER_KEY("name");
      },
    },
    email: {
      set(value) {
        this.SET_USER_FIELD({ key: "email", value });
      },
      get() {
        return this.GET_USER_KEY("email");
      },
    },
    contact: {
      set(value) {
        this.SET_USER_FIELD({ key: "contact", value });
      },
      get(){
        return this.GET_USER_KEY("contact");
      }
    },
    gender: {
      set(value) {
        this.SET_USER_FIELD({ key: "gender", value });
      },
      get() {
        return this.GET_USER_KEY("gender");
      },
    },
    country_code: {
      set(value) {
        this.SET_USER_FIELD({ key: "country_code", value });
      },
      get() {
        return this.GET_USER_KEY("country_code");
      },
    },
    genderList() {
      return GENDER_LIST.map((g) => {
        return {
          ...g,
          title: this.$vuetify.lang.t(`$vuetify.${g.value}`),
        };
      });
    },
    rules() {
      return {
        validEmail,
        required,
      };
    },
  },
  methods: {
    ...userModule.mapMutations(["SET_USER_FIELD", "CLEAR_CREATE_USER"]),
    nextStep() {
      if (!this.validPhone) {
        window.showAlert("Please Enter Valid Phone Number", "Error");
        return;
      }

      if (this.IS_VALID_INPUT.length > 0) {
        window.showAlert(`${this.IS_VALID_INPUT} are missing...!`, "Error");
        return;
      }
      this.SET_USER_FIELD({ key: "formatted_contact", value: this.formatted_number });
      this.$router.push("/register-2");
    },
    validatePhone(phone) {
      const { countryCode, isValid, formattedNumber } = phone;
      this.validPhone = isValid;
      this.country_code = countryCode;
      this.formatted_number = formattedNumber;
    },
    setPlace(place) {
      const { geometry, formatted_address, address_components } = place;
      const latitude = geometry.location.lat();
      const longitude = geometry.location.lng();
      const address = formatted_address;
      let country, city, postal_code;

      // ["postal_code", "country", "locality"];
      address_components.forEach((component) => {
        if (component.types.includes("postal_code"))
          postal_code = component.long_name;
        if (component.types.includes("country")) country = component.long_name;
        if (component.types.includes("locality")) city = component.long_name;
      });

      this.SET_USER_FIELD({ key: "latitude", value: latitude });
      this.SET_USER_FIELD({ key: "longitude", value: longitude });
      this.SET_USER_FIELD({ key: "address", value: address });
      this.SET_USER_FIELD({ key: "country", value: country });
      this.SET_USER_FIELD({ key: "city", value: city });
      this.SET_USER_FIELD({ key: "postal_code", value: postal_code });

      this.addAddress = false;
    },
    goBack() {
      this.CLEAR_CREATE_USER();
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: calc(100vh - 56px - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.paws-top {
  position: absolute;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
}

.enter-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  height: 56px;
  padding: 0 12px;

  p {
    margin-bottom: 0;
  }
}

.number-input {
  &::v-deep input {
    border-color: #9e9e9e;
  }
}

.address-comp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
